* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.modal-open {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a, button {
  color: black;
  text-decoration: none;
  font-family: Inter, sans-serif;
  border: none;

}

.DraftEditor-root {
  border: 1px solid #eee;
  margin: 2rem 0;
  border-radius: 0.5rem;
  width: 100%;
}

.DraftEditor-editorContainer {
  padding: 1.5rem;
}

.public-DraftEditor-content {
  min-height: 20rem;
}

